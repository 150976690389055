//export default class Main {

//	constructor() {
//		this.init();
//	}

//	init() {
//		alert('ciao');
//		const body = document.querySelector('body');
//		body.classList.add('ready');

//		const mainContainerNode = document.querySelector('main .container');

//		const messageNode = document.createElement('div');
//		messageNode.innerHTML = /* html */ `<p>
//			I'm an ES6 module! <a class="btn--link" href="https://developer.mozilla.org/it/docs/Web/JavaScript/ECMAScript_6_support_in_Mozilla" target="_blank">ES6</a>
//		</p>`;
//		mainContainerNode.appendChild(messageNode);
//	}

//}

//const main = new Main();

var windowW,
	windowH,
	headerH,
	bodyH,
	sideFilterTop,
	footerTop,
	correlatiTop,
	fakeFooterTop,
	filterTop,
	mapH;

/*--------------------------------------------------
FUNZIONI
--------------------------------------------------*/
function myVar() {
	windowW = $(window).width();
	windowH = $(window).height();
	headerH = $('.header').outerHeight();
	bodyH = $('body').height();
	filterTop = $('.col-filter-dx').length ? $('.col-filter-dx').offset().top : 0;
	mapH = windowH - filterTop - 20;
	//console.log(windowH + '/' + filterTop + '/' + headerH);
	if ($('.side-filter').length) {
		sideFilterTop = $('.side-filter').offset().top - 20;
	}
	if ($('#gmap').length) {
		$('#gmap').height(mapH);
	}

	footerTop = ($('.footer').offset().top - windowH) + headerH;
	if ($('.elenco-correlati').length) {
		correlatiTop = ($('.elenco-correlati').offset().top - windowH) + headerH;
	}

	if (!$('.elenco-correlati').length) {
		fakeFooterTop = footerTop;
	} else {
		fakeFooterTop = correlatiTop;
	}
}

function controllScroll(scrollo) {
	//console.log(scrollo + '/ filterTop: ' + sideFilterTop + '/ footerTop: ' + footerTop);

	if ($('.slideshow').not($('.landing .slideshow')).length) {
		if (scrollo > $(window).height() / 3) {
			$('.header').addClass('fixed');
		} else {
			$('.header').removeClass('fixed');
		}
	}

	if (scrollo > 40) {
		$('.scroll-down, .scroll-bottom').addClass('disabled');
	} else {
		$('.scroll-down, .scroll-bottom').removeClass('disabled');
	}

	if (scrollo > windowH - (headerH + 40)) {
		$('.scroll-top').addClass('active');

		if (scrollo > $('.footer').offset().top + $('.footer').height() - $(window).height()) {
			$('.scroll-top').addClass('arrived');
		} else {
			$('.scroll-top').removeClass('arrived');
		}
	} else {
		$('.scroll-top').removeClass('active');
	}

	if (!isMobile.tablet) {
		if (scrollo > sideFilterTop) {
			$('.side-filter').addClass('fixed').css({ 'top': headerH + 20, 'width': $('.col-filter-sx').outerWidth() });
		} else {
			$('.side-filter').removeClass('fixed').css({ 'top': 0 });
		}
	}

	if (scrollo > fakeFooterTop) {
		$('.side-filter').css({ 'top': (headerH + 20) - (scrollo - fakeFooterTop) });
	}

	//console.log(scrollo);

	if ($('.col-filter-dx').length) {
		if (scrollo > $('.col-filter-dx').offset().top - (windowH / 2) && scrollo > headerH) {
			$('.open-filters').addClass('active');
		} else {
			if (!$('.side-filter').hasClass('active')) {
				$('.open-filters').removeClass('active');
			}
		}
	}
}

function homepageRender() {
	if ($('.magnetic-scroll').size() && $(window).width() > 768 && $('html.touch').size() != 1) {

		$('body').addClass('fullpage');

		$('.magnetic-scroll').fullpage({

			licenseKey: '8AB6B1ED-6647439B-AE46917C-7D6879D4 ',
			//Navigation

			//Scrolling
			css3: true,
			scrollingSpeed: 700,
			autoScrolling: true,
			fitToSection: true,
			fitToSectionDelay: 1000,
			scrollBar: true,
			easing: 'easeInOutCubic',
			easingcss3: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
			loopBottom: false,
			loopTop: false,
			loopHorizontal: false,
			continuousVertical: false,
			//normalScrollElements: '#news, #footer',
			normalScrollElements: '',
			scrollOverflow: false,
			touchSensitivity: 15,
			normalScrollElementTouchThreshold: 5,

			//Accessibility
			keyboardScrolling: true,
			animateAnchor: true,
			recordHistory: true,

			//Design
			controlArrows: false,
			verticalCentered: false,
			resize: false,
			paddingTop: '81px',
			paddingBottom: '0',
			fixedElements: '',
			responsiveWidth: 0,
			responsiveHeight: 0,

			//Custom selectors
			sectionSelector: '.magnetic',
			slideSelector: '.no',

			//events
			onLeave: function (index, nextIndex, direction) { },
			afterLoad: function (anchorLink, index) { },
			afterRender: function () { },
			afterResize: function () { },
			afterSlideLoad: function (anchorLink, index, slideAnchor, slideIndex) { },
			onSlideLeave: function (anchorLink, index, slideIndex, direction, nextSlideIndex) { }
		});
	}
}

function subMenu() {
	$('.header .menu a').not($('.header .menu .actions-mobile a')).not($('.header .menu .social-mobile a')).on('click', function () {
		//closeMenuAny();
		$('.cerca-panel, .langs_wrap').removeClass('active');
		$('.header .menu a').removeClass('on');
		$(this).addClass('on');
		if (windowW > 991) {
			//PARTE DESKTOP
			var myGrid = $(this).attr('data-menu');
			console.log(myGrid);
			$('.submenu').not($('.submenu-mobile')).addClass('active');
			$('.service-menu .close').addClass('active');
			$('.show-menu').not($('.submenu-mobile .show-menu')).each(function () {
				if ($(this).attr('data-menu') == myGrid) {
					$('.show-menu.active').removeClass('active');
					$(this).addClass('active');
				}
			});
			$('.submenu .col-dx .box-lanci').each(function () {
				if ($(this).attr('data-box') == myGrid) {
					$('.submenu .col-dx .box-lanci.active').removeClass('active');
					$(this).addClass('active');
				}
			});
		} else {
			//PARTE MOBILE
			var myGrid = $(this).attr('data-menu');
			$('.submenu-mobile').addClass('active');
			$('.submenu-mobile .show-menu').each(function () {
				if ($(this).attr('data-menu') == myGrid) {
					$('.show-menu.active').removeClass('active');
					$(this).addClass('active');
					//menuMobile();
				}
			});
			$('.return').addClass('active');
		}
		return false;
	});

	$('.service-menu .close').on('click', function () {
		$('.header .menu a').removeClass('on');
		$('.submenu, .cerca-panel, .langs_wrap').removeClass('active');
		$(this).removeClass('active');
		$('.show-menu.active').removeClass('active');
		$('#search-fast').removeClass('full');
		$('#search-advanced').show();
		return false;
	});
}

function hoverMenu() {
	$('.header .menu .menu-primary').each(function () {
		var myTxt = $('.real-menu', this).html();
		$(this).append("<div class='fake-menu'>" + myTxt + "</div>");
	});
}

function menuMd() {
	if (windowW < 1199 && windowW > 767) {
		var myHDefaultProdotti = 0;
		var myHDefaultProgetti = 0;
		$('.show-menu.prodotti .cat-menu ul').each(function () {
			var myH = $(this).height();
			//console.log(myH);			
			if (myH > myHDefaultProdotti) {
				myHDefaultProdotti = myH;
			}
		});
		$('.show-menu.progetti .cat-menu ul').each(function () {
			var myH = $(this).height();
			//console.log(myH);
			if (myH > myHDefaultProgetti) {
				myHDefaultProgetti = myH;
			}
		});
		$('.show-menu.prodotti .cat-menu ul').height(myHDefaultProdotti);
		$('.show-menu.progetti .cat-menu ul').height(myHDefaultProgetti);
	} else {
		$('.cat-menu ul').css({ 'height': 'auto' });
	}
}

function menuMobile() {
	$('.hamburger a').on('click', function () {
		if (!$(this).hasClass('active')) {
			$(this).addClass('active');
			$('.header').addClass('header-mobile');
		} else {
			$(this).removeClass('active');
			$('.header').removeClass('header-mobile');
			$('.submenu').removeClass('active');
			$('.show-menu').removeClass('active');
			$('.submenu-mobile .cat-menu ul.active').removeClass('active');
			$('.return').removeClass('active');
		}
		return false;
	});

	//SOTTO MENU MOBILE
	$('.submenu-mobile .tit.small').on('click', function () {
		$(this).next().addClass('active');
	});

	//RETURN
	$('.return a').on('click', function () {
		if ($('.submenu-mobile .cat-menu ul').hasClass('active')) {
			$('.submenu-mobile .cat-menu ul.active').removeClass('active');
		} else {
			$('.submenu, .show-menu, .return').removeClass('active');
		}
		return false;
	});

	//CERCA
	$('.close-cerca').on('click', function () {
		$('.cerca-panel').removeClass('active');
		return false;
	});

	//LINGUE
	$('.header .menu .actions-mobile .mercati').on('click', function () {
		if (!$('.langs_wrap-mobile').hasClass('active')) {
			$('.langs_wrap-mobile').addClass('active');
		} else {
			$('.langs_wrap-mobile').removeClass('active');
		}
		return false;
	});
}

function cercaRicerca() {
	$('.service-menu .cerca').on('click', function () {
		$('.header .menu a').removeClass('on');
		$('.service-menu .close').addClass('active');
		$('.submenu').removeClass('active');
		$('.langs_wrap').removeClass('active');
		$('.cerca-panel').addClass('active');
		return false;
	});
}

function selectLang() {
	if (windowW > 991) {
		$('.service-menu .mercati a, .footer .mercati').on('click', function () {
			$('.header .menu a').removeClass('on');
			$('.service-menu .close').addClass('active');
			$('.submenu').removeClass('active');
			$('.cerca-panel').removeClass('active');
			$('.langs_wrap').addClass('active');
			return false;
		});
	}
}

function selectMarket() {
	$('.langs_wrap #Markets a').on('click', function () {
		$('.col-langs').addClass('active');
		$('.langs_wrap #Markets a.active').removeClass('active');
		$(this).addClass('active');
		return false;
	});
}

function closeMenuAny() {
	if (windowW >= 991) {
		$('body').on('click', function (e) {
			if (!$(e.target).closest('.submenu').hasClass('active') && !$(e.target).closest('.cerca-panel').hasClass('active') && !$(e.target).closest('.langs_wrap').hasClass('active')) {
				$('.service-menu .close').click();
			}
			//console.log($(e.target));
		});
	}
}

function chechboxFake() {
	$('input[type="checkbox"]').on('click', function () {
		if ($(this).prop("checked") == true) {
			$(this).parent().addClass('checked');
		} else if ($(this).prop("checked") == false) {
			$(this).parent().removeClass('checked');
		}
	});

	$('.radiogrp').each(function () {
		$('input[type="radio"]', this).on('click', function () {
			if ($(this).prop("checked") == true) {
				//console.log($(this).parents());
				$(this).closest('.radiogrp').find('.checked').removeClass('checked');
				$(this).parent().addClass('checked');
			}
		});
	});
}

function scrollDown() {
	$('.scroll-down, .scroll-bottom').on('click', function () {
		$('body,html').stop().animate({ scrollTop: $(this).next().offset().top - $('.header').outerHeight() }, 'slow');
		return false;
	});
}

function scrollTop() {
	$('.scroll-top').on('click', function () {
		$('body,html').stop().animate({ scrollTop: $('body').offset().top }, 'slow');
		return false;
	});
}

function bigSlideshow() {
	$('.slideshow').slick({
		autoplay: true,
		autoplaySpeed: 5000,
		arrows: false,
		infinite: true,
		dots: true,
		pauseOnHover: false
	});

	$('.slideshow .item').each(function () {
		var myTit = $('.tit-slide', this).html();
		var shortTit = jQuery.trim(myTit).substring(0, 20).trim(this) + "...";
		var myTxt = $('.txt', this).html();
		var myIndex = $(this).closest('.slick-slide').index() - 1;
		//console.log(myTit + ' / ' + myTxt + ' / ' + myIndex);

		$('.slideshow .slick-dots li:eq(' + myIndex + ') button').html('<div class="tit-dots">' + myTit + '</div><div class="txt">' + myTxt + '</div>');

	});
}

function slideshowCollections() {
	$('.slideshow-collections').slick({
		dots: true,
		infinite: false,
		speed: 300,
		slidesToShow: 7.5,
		//centerMode: true,
		//variableWidth: true,
		responsive: [
			{
				breakpoint: 2000,
				settings: {
					slidesToShow: 6.5
				}
			},
			{
				breakpoint: 1800,
				settings: {
					slidesToShow: 5.5
				}
			},
			{
				breakpoint: 1500,
				settings: {
					slidesToShow: 4.5
				}
			},
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 3.5
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2.5
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					arrows: false,
				}
			}
		]
	});

	//$('.slideshow-collections').on('afterChange', function (event, slick, currentSlide, nextSlide) {
	//	var diffSlide;
	//	if (windowW >= 2200) {
	//		diffSlide = 6;
	//	}
	//	if (windowW >= 1860) {
	//		diffSlide = 5;
	//	}

	//	if (windowW < 1860) {
	//		diffSlide = 4;
	//	}

	//	if (windowW <= 1440) {
	//		diffSlide = 3;
	//	}
	//	if (windowW <= 1199) {
	//		diffSlide = 2;
	//	}
	//	var nSLide = $('.slideshow-collections .slick-slide').length;
	//	var diff = nSLide - diffSlide;
	//	if (currentSlide >= diff) {
	//		$('.slideshow-collections .slick-next').addClass('slick-disabled');
	//	} else {
	//		$('.slideshow-collections .slick-next').removeClass('slick-disabled');
	//	}
	//});
}

function slideshowHorizontal() {
	$('.slideshow-horizontal').slick({
		centerMode: true,
		centerPadding: '80px',
		slidesToShow: 1,
		dots: true,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					autoplay: true,
					autoplaySpeed: 5000,
					centerMode: false,
					arrows: false,
					init: slideshowHorizontalDots()
				}
			}
		]
	});

	$('.counter-slide .of').html($('.slideshow-horizontal .slick-dots li').length);

	slideshowHorizontalDots();

	$('.slideshow-horizontal').on('afterChange', function (event, slick, currentSlide, nextSlide) {
		//console.log('ho cambiato');
		var myIndex = $('.slideshow-horizontal .slick-dots li.slick-active').index() + 1;
		$('.counter-slide .number').html(myIndex);
	});
}

function slideshowHorizontalDots() {
	$('.slideshow-horizontal .slick-dots li').css({ 'flex-grow': $('.slideshow-horizontal .slick-dots li').length });
}

function slideshowNews() {
	$('.slideshow-news').slick({
		centerMode: true,
		centerPadding: '80px',
		slidesToShow: 3,
		slidesToScroll: 3,
		dots: true,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					centerMode: false,
					arrows: false,
					autoplay: true,
					autoplaySpeed: 5000
				}
			}
		]
	});
}

function slideshowApp() {
	$('.slideshow-app').slick({
		//centerMode: true,
		//centerPadding: '8px',
		slidesToShow: 4,
		slidesToScroll: 1,
		dots: true,
		arrows: false,
		infinite: false,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	});
}

function slideImOpzionale() {
	$('.slide-dimensioni').slick({
		arrows: false,
		dots: true,
		infinite: false
	});
}

function slideSerie() {
	$('.slide-serie').slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 5000,
		dots: true,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					//dots: false,
					arrows: false,
					//autoplay: true,
					//autoplaySpeed: 5000,
					//infinite: true
				}
			}
		]
	});
}

function slideFull() {
	$('.slide-full').slick();
}

function openFilters() {
	$('.tit-box').on('click', function () {
		$(this).closest('.box-filter').toggleClass('closed');
		return false;
	});
}

function imgDimensioni() {
	if ($('.dimensioni').length) {
		var myHtml = $('.box-prodotto-fake').html();
		//console.log(myHtml);
		$('.slide-dimensioni').append(myHtml);
		$('.box-prodotto-fake').remove();
	}
}

//function activateChecks() {
//	$('.all-selector').on('change', function () {
//		if ($(this).prop('checked')) {
//			$('input[data-raggr="' + $(this).data('raggrx') + '" ]').prop('checked', true);
//		}
//		else {
//			$('input[data-raggr="' + $(this).data('raggrx') + '" ]').prop('checked', false);
//		}
//	});

//	$('.object-selector').on('change', function () {
//		if (!$(this).prop('checked')) {
//			$('input[data-raggrx="' + $(this).data('raggr') + '" ]').prop('checked', false);
//		}
//	});
//}

function tabsDimensioni() {
	$('.dimensioni .nav-tabs a').not($('.dimensioni .nav-tabs .d-tip a')).on('click', function () {
		if (!$(this).parent().hasClass('active')) {
			$('.dimensioni .nav-tabs li.active').removeClass('active');
			$(this).parent().addClass('active');
		}

		var myTab = $(this).attr("href");

		$('.dimensioni .tab-content .tab-pane.active').removeClass('active');
		//$('.dimensioni .tab-content ' + myTab + '').addClass('active');

		if (myTab == "#01_referenze")
			loadReferenze(codDimensione);

		if (myTab == "#01_overview")
			loadOverview(codDimensione);

		return false;
	});
}

function tabsDida() {
	$('.dimensioni-child-txt .nav-tabs a').on('click', function () {
		if (!$(this).parent().hasClass('active')) {
			$('.dimensioni-child-txt .nav-tabs li.active').removeClass('active');
			$(this).parent().addClass('active');
			setTimeout(function () {
				tooltip();
			}, 100);
		} else {
			$(this).parent().removeClass('active');
			$('.dimensioni-child .tab-pane.active').removeClass('active');
		}

		var myTabDida = $(this).attr("href");

		$('.dimensioni-child .tab-pane.active').removeClass('active');


		return false;
	});
}

function tooltip() {
	$('.btn-tooltip').hover(function () {
		if ($(this).attr('title') != null) {
			var myTooltip = $(this).attr('title');
		}
		if ($(this).attr('data-original-title') != null) {
			var myTooltip = $(this).attr('data-original-title');
		}
		$(this).append('<div class="mytooltip">' + myTooltip + '</div>');
	}, function () {
		$('.mytooltip').remove();
	});
}

function openFiltersMobile() {
	$('.open-filters a').on('click', function () {
		$(this).toggleClass('active');
		$('.side-filter').toggleClass('active');
		return false;
	});
}

function openRicercaRapida() {
	$('.box-filtri-txt a').on('click', function () {
		$('.service-menu .cerca').click();
		$('#search-fast').addClass('full');
		$('#search-advanced').hide();
		return false;
	});
}

function RicercaRapidaRadio() {
	$(".radiogrp input").click(function () {
		$(".radiogrp input").removeClass('active');
		let value = $(this).attr('data-value');
		$(this).addClass('active');
		$('#form_cerca_rapido input[type="text"]').hide();
		$('#form_cerca_rapido #' + value).show();
	})
}

function RicercaAvanzataRadio() {
	$(".radiogrp input").click(function () {
		let value = $(this).attr('data-href');
		$('#form_cerca_avanzata .btn').attr('href', value);
	})
}

function splitMappaGriglia() {
	$('.icons .pin').on('click', function () {
		if (!$(this).hasClass('active')) {
			$(this).addClass('active');
			$('.icons .elenco').removeClass('active');
			$('#gmap').addClass('active');
			$('.listing-progetti').addClass('disabled');
			$(window).resize();
		}
		return false;
	});

	$('.icons .elenco').on('click', function () {
		if (!$(this).hasClass('active')) {
			$(this).addClass('active');
			$('.icons .pin').removeClass('active');
			$('#gmap').removeClass('active');
			$('.listing-progetti').removeClass('disabled');
		}
		return false;
	});
}

function accordionSchedaProdotto() {
	$('.accordion').each(function () {
		$('.accordion-title', this).on('click', function () {
			if (!$(this).parent().hasClass('closed')) {
				//$('.accordion.active').removeClass('active');
				$(this).parent().addClass('closed');
			} else {
				$(this).parent().removeClass('closed');
			}
			return false;
		});
	});
}

function downloadsSchedaPrdotto() {
	$('.filter-prodotto ul').each(function () {
		$('.tit-group', this).on('click', function () {
			if (!$(this).closest('ul').hasClass('closed')) {
				$(this).closest('ul').addClass('closed');
			} else {
				$(this).closest('ul').removeClass('closed');
			}
			return false;
		});
	});
}

function ipCheck() {
	$('.ip-check .close, .ip-check .btn-close').on('click', function () {
		$('.ip-check').remove();
		return false;
	});
}

function gammaRow() {
	if (windowW < 1199) {
		var nRow = $('.row-gamma.gamma-immagini > div').length;
		if (windowW > 767) {
			var gap = 19;
		} else {
			var gap = 28;
		}
		$('.row-gamma').css({ 'min-width': (nRow * $('.row-gamma .image').width()) + (gap * (nRow - 1)) });
		console.log(gap);
		console.log(nRow);
	}

}

function openContacts() {
	$('.contact-pretitle').on('click', function () {
		$(this).toggleClass('active');
		return false;
	});
}

function tabLanding() {
	$('.landing .tabs ul li').on('click', function () {
		var myIndex = $(this).index();
		if (!$(this).hasClass('selected')) {
			$('.landing .tabs ul li.selected').removeClass('selected');
			$(this).addClass('selected');
			$('.landing .content-tab.active').removeClass('active');
			$('.landing .content-tab:eq(' + myIndex + ')').addClass('active');
		}

		//console.log(myIndex);
		return false;
	});
}

function landingMenu() {
	$('.landing-menu a').on('click', function () {
		var anchor = $(this).attr('data-anchor');
		//console.log(anchor);
		$('body,html').stop().animate({ scrollTop: $('#' + anchor + '').offset().top - ($('.header').outerHeight() + $('.landing-menu').outerHeight() - 1) }, 'slow');
		$('.landing-menu').removeClass('menu-active');
		if (!$(this).hasClass('active')) {
			$('.landing-menu a.active').removeClass('active');
			$(this).addClass('active');
		}
		return false;
	});

	$('.menu-mobile').on('click', function () {
		$(this).closest('.landing-menu').toggleClass('menu-active');
		return false;
	});
}

/*--------------------------------------------------
DOC READY
--------------------------------------------------*/
$(document).ready(function (e) {
	myVar();

	setTimeout(function () {
		if (!$('.slideshow').not($('.landing .slideshow')).length) {
			$('.header').addClass('fixed');
			$('body').css({ 'padding-top': headerH });
		}
	}, 300);

	//if ($('.side-filter').length) {
	//	if ($('.myfilters .col-9').height() > $('.myfilters .col-3').height()) {
	//		$('.myfilters .col-3 .side-filter').height($('.myfilters .col-9').height());
	//	}
	//}

	//homepageRender();
	subMenu();
	//hoverMenu();
	//menuMd();
	menuMobile();
	cercaRicerca();
	selectLang();
	selectMarket();
	chechboxFake();
	closeMenuAny();
	bigSlideshow();
	scrollDown();
	scrollTop();
	slideshowCollections();
	slideshowHorizontal();
	slideshowNews();
	//slideshowApp();
	imgDimensioni();
	slideImOpzionale();
	slideSerie();
	slideFull();
	openFilters();
	tabsDimensioni();
	tabsDida();
	tooltip();
	openFiltersMobile();
	openRicercaRapida();
	RicercaRapidaRadio();
	RicercaAvanzataRadio();
	splitMappaGriglia();
	accordionSchedaProdotto();
	downloadsSchedaPrdotto();
	ipCheck();
	openContacts();
	gammaRow();
	tabLanding();
	landingMenu();
	if (window.WOW) {
		new WOW().init();
	}

	$("#Market_mob").bind("change", function () {
		window.location = $(this).val();
	});

	$("#Langs_mob").bind("change", function () {
		window.location = $(this).val();
	});

	$('.dimensioni-child-img a, .dimensioni-child-txt .title a').on('click', function () {
		var myvar = $(this).attr('href');
		console.log(myvar);
		$('.nav-tabs a').each(function () {
			if ($(this).attr('href') == myvar) {
				$(this).click();
			}
		});
		return false;
	});

	$('.risultati-dimensioni .nav-tabs li.d-tip span, .risultati-dimensioni .wrap-dida .tab-content .tab-pane .tbl-prod tr td .btn-download').on('click', function () {
		$('body,html').stop().animate({ scrollTop: $(document).scrollTop() }, 'slow');
	});
});


/*--------------------------------------------------
WIN RESIZE
--------------------------------------------------*/
$(window).resize(function () {
	myVar();
	//menuMd();
	slideshowHorizontalDots();
	gammaRow();

	$('.side-filter').css({ 'width': $('.col-filter-sx').outerWidth() });

	if (windowW < 1025) {
		$('.side-filter.filter-prodotto').css({ 'width': $('.col-filter-sx').outerWidth() + 60 });
	} else {
		$('.side-filter.filter-prodotto').css({ 'width': $('.col-filter-sx').outerWidth() });
	}

	if (windowW > 1199) {
		$('.landing-menu').removeClass('menu-active');
	}
});

/*--------------------------------------------------
WIN LOAD
--------------------------------------------------*/
$(window).load(function () {
	myVar();
	tooltip();
});

/*--------------------------------------------------
WIN SCROLL
--------------------------------------------------*/
$(window).scroll(function (e) {
	controllScroll($(document).scrollTop());
});

/*--------------------------------------------------
ORIENTATION CHANGE
--------------------------------------------------*/
$(window).on("orientationchange", function () {
	if ($(window).width() < 991) //breakpoint inverso per determinare quando da portrait passo a landscape
		$(".header").removeClass("header-mobile");
});
